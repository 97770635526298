import React, { useEffect } from 'react';
import {
  Form, Modal, Button, Input, Checkbox, InputNumber,
} from 'antd';
import { useFormFieldProvider } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';

export interface VolunteerWorkModalProps {
  name: string,
  visible: boolean,
  value?: {
    i: number,
    item: object,
  },
  onCancel: () => void,
}

const VolunteerWorkModal = ({
  name, visible, value, onCancel,
}: VolunteerWorkModalProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, setFieldsValue } = useFormFieldProvider(form);

  useEffect(() => {
    if (!visible || !value?.item) {
      form.resetFields();
    } else if (value) {
      setFieldsValue(value.item);
    }
  }, [visible]);

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-900)' }}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form
        className={formStyles.form}
        form={form}
        name={name}
        onFieldsChange={onFieldsChange}
      >
        <h3><span className="turquoise">Vrijwilligerswerk toevoegen</span></h3>

        <Form.Item
          label="Naam organisatie"
          name="associationName"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Input type="text" autoFocus />
        </Form.Item>

        <Form.Item
          label="Welke rol had je hier?"
          name="position"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Voor hoeveel uur per maand?"
          name="hoursPerMonth"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <InputNumber type="number" />
        </Form.Item>

        <Form.Item
          className="form-item-checkbox"
          name="partiallyAbroad"
          valuePropName="checked"
        >
          <Checkbox>Deze ervaring was (deels) in het buitenland</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
          >
            Volgende
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default VolunteerWorkModal;
