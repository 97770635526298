import { Button } from 'antd';
import React, { useMemo } from 'react';
import { LayoutWizard, PickerHeader } from '../';
import styles from './prePicker.module.css';
import moment from 'moment';
import gql from 'graphql-tag';
import { useBackendQuery } from '../../hooks/useBackendQuery';
import { useAuth, useEvent } from '../../hooks';
import { useBackendMutation } from '../../hooks/useBackendMutation';

const ENROLL = gql`
  mutation enroll($input: EnrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`;

const PreEventsPicker = () => {
  const { me, refetch } = useAuth();
  const { tbEvent } = useEvent();
  const { data } = useBackendQuery(gql`
    query {
      currentTBEvent {
        programItemTypeCount
      }
    }
  `);
  const [enroll, { loading }] = useBackendMutation(ENROLL, { onCompleted: refetch });
  const enrolled = useMemo(() => Boolean(me?.tbEventEnrollments?.find(e => e.tbEvent.id === tbEvent?.id)), [
    me,
    tbEvent,
  ]);
  const onEnroll = async () => {
    await enroll({
      variables: {
        input: {
          type: 'TB_EVENT',
          id: tbEvent?.id,
        },
      },
    });
  };

  return (
    <LayoutWizard>
      <div className={styles.preEventsPicker}>
        <PickerHeader />

        <div className="container-sm">
          <h3>
            <span className="turquoise">Techniek Bedrijven Evenement {moment(tbEvent?.date).format('YYYY')}</span>
          </h3>
          {enrolled ? (
            <>
              <p>
                Het {tbEvent?.name} op {moment(tbEvent?.date).format('DD MMMM')} zal bestaan uit{' '}
                {data?.currentTBEvent?.programItemTypeCount} bedrijfsonderdelen. We vragen je nu om per
                bedrijfsonderdeel je voorkeur uit te spreken.
              </p>
              <p>
                Op de bedrijvenpagina's vind je informatie over de bedrijven en de bedrijfsonderdelen die zij aanbieden.
                Deze informatie kan je helpen bij het kiezen van je voorkeuren.
              </p>
              <p>
                Zonder deze voorkeuren is je aanmelding voor het evenement niet compleet. Zorg ervoor dat je dit vóór{' '}
                {moment(tbEvent?.finalSignupDate).format('DD MMMM')} doet, zodat wij de beste match voor jou kunnen
                vinden!
              </p>
              <p className={styles.center}>
                <Button type="primary" href="/student/programma-invullen/" block style={{ maxWidth: 400 }}>
                  Aan de slag
                </Button>
              </p>
            </>
          ) : (
            <>
              <p>
                Je bent nog niet ingeschreven voor het {tbEvent?.name} op {moment(tbEvent?.date).format('DD MMMM')}. Wil
                je je inschrijven? Klik dan op de knop hieronder!
              </p>
              <p className={styles.center}>
                <Button type="primary" block style={{ maxWidth: 400 }} onClick={onEnroll} loading={loading}>
                  Inschrijven
                </Button>
              </p>
            </>
          )}
        </div>
      </div>
    </LayoutWizard>
  );
};

export default PreEventsPicker;
