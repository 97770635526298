import { Link } from 'gatsby';
import React from 'react';
import breadcrumbArrow from '../../images/breadcrumb-arrow.svg';
import styles from './Breadcrumb.module.css';

const Breadcrumb = () => {
  return <Link
    to="/student/account/"
    className={styles.breadcrumb}>
    <img className={styles.arrow} src={breadcrumbArrow} alt="back-arrow"
  />
    Terug naar Mijn account
  </Link>
}

export default Breadcrumb;
