import React from 'react';
import { Form, Button, Row, Col, Checkbox } from 'antd';
import { useStep } from '../../../hooks';
import { pick } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';

const SideActivityOptions = [
  { label: '(Bij)baan en of stage', value: 'hasJobs' },
  { label: 'Commissies', value: 'hasCommittees' },
  { label: 'Bestuur', value: 'hasBoards' },
  { label: 'Topsport', value: 'hasTopSport' },
  { label: 'Eigen bedrijf', value: 'hasOwnBusiness' },
  { label: 'Vrijwilligerswerk', value: 'hasVolunteerWork' },
  { label: 'Studententeam', value: 'hasStudentTeam' },
];

interface FormData {
  hasJobs: boolean,
  hasCommittees: boolean,
  hasBoards: boolean,
  hasTopSport: boolean,
  hasOwnBusiness: boolean,
  hasVolunteerWork: boolean,
  hasStudentTeam: boolean,
};

const SideActivities = () => {
  const { initialValues, onFinish } = useStep<Partial<FormData>>(
    (me) => pick(me, ['hasJobs', 'hasCommittees', 'hasBoards', 'hasTopSport', 'hasOwnBusiness', 'hasVolunteerWork', 'hasStudentTeam']),
  )

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={(values) => onFinish(values as FormData, true)}
    >
      <h3><span className="turquoise">Heb je naast je studie andere dingen gedaan?</span></h3>

      <Row gutter={10}>
        {SideActivityOptions.map(({ label, value}) =>
          <Col key={value} lg={12}>
            <Form.Item
              name={value}
              className="form-item-checkbox"
              valuePropName="checked"
              style={{ padding: '0 12px', marginBottom: '8px' }}
            >
              <Checkbox>{label}{label === 'Studententeam' && ' *'}</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      <p style={{ padding: "0 12px" }}><small>* Initiatieven in samenwerking met de universiteit zoals Dreamteam, Solar Team etc.</small></p>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Opslaan
        </Button>
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
        >
          Ik heb alleen gestudeerd
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SideActivities;
