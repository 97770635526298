import React, { FC } from 'react';
import { Link } from 'gatsby';
import { CalendarOutlined, CaretRightOutlined } from '@ant-design/icons';
import styles from './spotlightCard.module.css';
import { Col, Row, Space } from 'antd';
import moment from 'moment';

export interface SpotlightCardProps {
  date: Date | string;
  description?: string;
  imageUrl: string;
  location: string;
  title: string;
  url: string;
  cover?: boolean;
}

const SpotlightCard: FC<SpotlightCardProps> = ({ imageUrl, title, date, location, description, url, cover }) => {
  return (
    <Link className={styles.link} to={url}>
      <Row className={styles.card}>
        <Col xs={10} className={styles.imageContainer}>
          <img src={imageUrl} className={cover ? styles.cover : ''} />
        </Col>
        <Col xs={12} className={styles.info}>
          <p>
            <b>{title}</b>
          </p>
          <Space direction="horizontal" size={8}>
            <CalendarOutlined className={styles.icon} />
            <span>
              {moment(date).format('DD MMM YYYY')} - {location}
            </span>
          </Space>
          <p>{description}</p>
        </Col>
        <Col xs={2} className={styles.action}>
          <CaretRightOutlined className={styles.icon} />
        </Col>
      </Row>
    </Link>
  );
};
export default SpotlightCard;
