import React from 'react';
import { Form } from 'antd';
import { useStep } from '../../../hooks';
import { pick, UserJob } from '../../../utils';
import useLinkedForms from '../../../hooks/useLinkedForms';
import formStyles from '../../formComponents/formComponents.module.css';
import JobItem from '../../formComponents/jobItem';
import ItemsOverview from '../../formComponents/itemsOverview';
import JobsModal from './jobsModal';
import moment from 'moment';

interface FormData {
  jobs: Partial<UserJob>[]
}

const Jobs = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    me => ({
      jobs: (me.jobs ?? []).map(job => ({
        ...pick(job, ['id', 'companyName', 'jobTitle', 'type', 'hoursPerWeek', 'partiallyAbroad']),
        beginDate: job.beginDate ? moment(job.beginDate) : undefined,
        endDate: job.endDate ? moment(job.endDate) : undefined,
      })),
    }),
    ({ jobs }) => ({
      jobs: jobs.map(job => ({
        ...job,
        hoursPerWeek: Number.parseFloat(job.hoursPerWeek as string),
        beginDate: job.beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: job.endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
      })),
    })
  );

  const {
    form,
    linkedForm,
    onFormFinish,
    openEdit,
    openNew,
    shouldUpdate,
    destroyItem,
  } = useLinkedForms<FormData['jobs']>('jobs', onFinish)

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Form {...form} className={formStyles.form} initialValues={initialValues}>
        <h3>
          <span className="turquoise">Werkervaring</span>
        </h3>
        <p>Voeg hieronder al je werkervaring toe, zowel je huidige als eerder (bij)baan of stage.</p>

        <ItemsOverview
          field="jobs"
          name={{ singular: '(Bij)baan of stage', plural: 'werkervaringen' }}
          ItemComponent={JobItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />
      </Form>

      <JobsModal {...linkedForm} />
    </Form.Provider>
  );
}

export default Jobs;
