import React from 'react';
import ReactMarkdown from 'react-markdown';

export interface RichTextProps {
  text: string,
};

const RichText = ({ text }: RichTextProps) => {

  return (
    <section>
      <div className="container">
        <ReactMarkdown source={text} />
      </div>
    </section>
  )
};

export default RichText;
