import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { tbEventOpenForSignup } from '../utils/tbEvent';
import { useBackendQuery } from './useBackendQuery';

const EVENT = gql`
  query {
    currentTBEvent {
      id
      active
      title: name
      name
      date
      finalSignupDate
      location
      studentsCanPickProgramItems
    }
  }
`;

const useEvent = () => {
  const { loading, data } = useBackendQuery(EVENT, {
    errorPolicy: 'ignore',
  });

  const tbEvent = data?.currentTBEvent;

  const openForSignup = useMemo(() => {
    return tbEventOpenForSignup(tbEvent);
  }, [tbEvent]);

  return {
    loading,
    openForSignup,
    tbEvent,
  };
};

export default useEvent;
