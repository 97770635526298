import React from 'react';
import { Button, Modal } from 'antd';
import { useActiveModal } from '../../hooks';
import Mailbox from '../../images/mailbox.svg';

const ConfirmSentModal = () => {
  const { modal, setActiveModal } = useActiveModal();

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-500)' }}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setActiveModal(null)}
      visible={modal === 'confirm-sent'}
      width={500}
    >
      <h3><span className="turquoise">You've got mail</span></h3>
      <p>
        Zodra je hebt bevestigd zullen we een aantal vragen aan je stellen om meer over je te weten
        te komen. Ook kun je dan je voorkeuren voor het evenement opgeven.
      </p>
      <p style={{textAlign: 'center' }}>
        <img src={Mailbox} alt="" style={{ maxWidth: '100%' }} />
      </p>
      <Button
        onClick={() => setActiveModal(null) }
        block
        style={{ marginTop: '32px' }}
      >
        Sluiten
      </Button>
    </Modal>
  );
};

export default ConfirmSentModal;
