import React from 'react';
import { navigate } from 'gatsby';
import { UserOutlined } from '@ant-design/icons';
import { useActiveModal } from '../../hooks';
import styles from './loginButton.module.css';

export interface LoginButtonProps {
  responsive?: Boolean
}

const LoginButton = ({ responsive }: LoginButtonProps) => {
  const { setActiveModal } = useActiveModal();

  const login = async () => {
    await setActiveModal('login');
    navigate('/student/account');
  }

  return (
    <a
      className={responsive ? styles.responsiveButton : styles.button}
      onClick={() => login()}
    >
      <UserOutlined />
    </a>
  );
};

export default LoginButton;
