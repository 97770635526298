import React from 'react';
import classnames from 'classnames/bind';
import styles from './companyStats.module.css';

const cx = classnames.bind(styles);

export interface CompanyStatusProps {
  statName?: string,
  value?: number,
  large?: Boolean
}

const CompanyStats = ({ statName, value, large }: CompanyStatusProps) => {
  return value ? <div className={cx('companyStats', { large })}>
    <h2>{value}</h2>
    <p>{statName}</p>
  </div> : null
};

export default CompanyStats;
