import React, { useEffect } from 'react';
import {
  Form, Modal, Button, InputNumber, Col, DatePicker, Row,
} from 'antd';
import { useFormFieldProvider } from '../../hooks';
import formStyles from './formComponents.module.css';

export interface ItemsModalProps<T> {
  name: string,
  visible: boolean,
  value?: {
    i: number,
    item: Partial<T>,
  },
  onCancel: () => void,
  children?: any
}

const ItemsModal = <T extends unknown>() => ({
  name, visible, value, onCancel, children,
}: ItemsModalProps<T>) => {
  const [form] = Form.useForm();
  const { onFieldsChange, setFieldsValue } = useFormFieldProvider(form);

  useEffect(() => {
    if (!visible || !value?.item) {
      form.resetFields();
    } else if (value) {
      setFieldsValue(value.item);
    }
  }, [visible]);

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-900)' }}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form
        className={formStyles.form}
        form={form}
        name={name}
        onFieldsChange={onFieldsChange}
      >
        {children}

        <Row gutter={10}>
          <Col xs={12}>
            <Form.Item
              label="Begin datum"
              name="beginDate"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <DatePicker
                className="datepickerStyle"
                format="MMMM YYYY"
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Eind datum (verwacht)"
              name="endDate"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <DatePicker
                className="datepickerStyle"
                format="MMMM YYYY"
                picker="month"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Hoeveel uur per week (gemiddeld)?"
          name="hoursPerWeek"
          rules={[{ required: true, message: 'vul dit in' }, { type: 'number', max: 40, message: 'Vul een getal in lager of gelijk aan 40' }]}
        >
          <InputNumber type="number" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
          >
            Volgende
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            block
            onClick={onCancel}
          >
            Annuleren
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ItemsModal;
