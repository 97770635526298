import React from 'react';
import DropletSection, { DropletSectionProps } from './dropletSection';
import { colors } from '../../utils';
import styles from './dropletSection.module.css';

interface DropletSectionListProps {
  title: string,
  sections: Array<DropletSectionProps>,
};

const DropletSectionList = ({ title, sections }: DropletSectionListProps) => {
  return (
    <section className={styles.list}>
      <div className="container">
        <h2>{title}</h2>
        <svg viewBox="0 0 1 0.74018508" width="0" height="0">
          <clipPath id="dropletRight" clipPathUnits="objectBoundingBox">
            <path d="M 0.98258707,0 C 0.24300697,0 0,0.23770746 0,0.50746268 0,0.77721791 0.18045993,0.9409011 0.45273632,0.94278607 0.61256066,0.94388957 0.825798,0.79248596 1,0.61940297 V 0.00248756 C 0.992621,0.00228343 0.990312,-4.3993643e-7 0.98258707,0 Z" />
          </clipPath>
          <clipPath id="dropletLeft" clipPathUnits="objectBoundingBox">
            <path d="M 0.01741293,0 C 0.75699303,0 1,0.23770746 1,0.50746268 1,0.77721791 0.81954007,0.9409011 0.54726368,0.94278607 0.38743934,0.94388957 0.174202,0.79248596 0,0.61940297 V 0.00248756 C 0.007379,0.00228343 0.009688,-4.3993643e-7 0.01741293,0 Z" />
          </clipPath>
        </svg>
        {sections.map((section, i) =>
          <DropletSection
            key={i}
            {...section}
            color={colors[i % 3]}
            alignment={['right', 'left'][i % 2]}
          />
        )}
      </div>
    </section>
  );
};

export default DropletSectionList;
