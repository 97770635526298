import React from 'react';
import { Row, Col } from 'antd';
import ReactMarkdown from 'react-markdown';
import styles from './confirmItem.module.css';

export interface ConfirmItemProps {
  title: string,
  description?: string,
  children: React.ReactNode,
};

const ConfirmItem = ({ title, description, children }: ConfirmItemProps) => (
  <div className={styles.confirmItem}>
    <Row>
      <Col flex="28px">
        {children}
      </Col>
      <Col flex="calc(100% - 62px)">
        <p>{title}</p>
        <ReactMarkdown
          className={styles.description}
          source={description}
        />
      </Col>
    </Row>
  </div>
);

export default ConfirmItem;
