import React from 'react';
import { Form } from 'antd';
import { useStep } from '../../../hooks';
import { pick, UserCommittee } from '../../../utils';
import useLinkedForms from '../../../hooks/useLinkedForms';
import formStyles from '../../formComponents/formComponents.module.css';
import CommitteeItem from '../../formComponents/committeeItem';
import ItemsOverview from '../../formComponents/itemsOverview';
import CommitteesModal from './committeesModal';
import moment from 'moment';

interface FormData {
  committees: Partial<UserCommittee>[]
}

const Committees = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    me => ({
      committees: (me.committees ?? []).map(committee => ({
        ...pick(committee, ['id', 'association', 'name', 'position', 'employmentType', 'hoursPerWeek']),
        beginDate: committee.beginDate ? moment(committee.beginDate) : undefined,
        endDate: committee.endDate ? moment(committee.endDate) : undefined,
      })),
    }),
    ({ committees }) => ({
      committees: committees.map(committee => ({
        ...committee,
        beginDate: committee.beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: committee.endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        hoursPerWeek: Number.parseFloat(committee.hoursPerWeek as string),
      })),
    })
  );

  const {
    form,
    linkedForm,
    onFormFinish,
    openEdit,
    openNew,
    shouldUpdate,
    destroyItem,
  } = useLinkedForms<FormData['committees']>('committees', onFinish)

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Form {...form} className={formStyles.form} initialValues={initialValues}>
        <h3>
          <span className="turquoise">Commissie ervaring</span>
        </h3>
        <p>Voeg hieronder al je relevante commissie ervaring toe, zowel je huidige als eerder ervaringen.</p>

        <ItemsOverview
          field="committees"
          name={{ singular: 'Commissie', plural: 'commissies' }}
          ItemComponent={CommitteeItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />
      </Form>

      <CommitteesModal {...linkedForm} />
    </Form.Provider>
  );
}

export default Committees;
