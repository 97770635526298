import React, { useState } from 'react';
import { Modal } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import styles from './videoEmbed.module.css';

export interface VideoEmbedProps {
  title?: string,
  embedCode: string,
  thumbnailUrl?: string,
  width?: number,
  height?: number,
};

const VideoEmbed = ({ title, embedCode, thumbnailUrl, width, height }: VideoEmbedProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {title && <h3><span className="turquoise">{title}</span></h3>}
      <a className={styles.videoEmbed} onClick={() => setVisible(true)}>
        <img src={thumbnailUrl} alt="Video thumbnail" width={width} height={height} />
        <CaretRightOutlined />
      </a>
      <Modal
        visible={visible}
        centered
        footer={null}
        closable={false}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
        wrapClassName="event-video"
      >
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
      </Modal>
    </>
  )
};

export default VideoEmbed;
