import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import './layout.css';

const LayoutWizard = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Helmet>
        <script>
          {`
            window.onUsersnapCXLoad = function(api) {
              api.init();
            }
            var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/4b1430dc-9faa-4cb5-ac25-9297aa8530b7?onload=onUsersnapCXLoad';
            document.getElementsByTagName('head')[0].appendChild(script);
          `}
        </script>
      </Helmet>
      <main>{children}</main>
    </>
  );
};

export default LayoutWizard;
