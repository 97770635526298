import React from 'react';
import { Button, Drawer } from 'antd';
import { useCookies } from 'react-cookie';
import styles from './cookieBar.module.css';

const CookieBar = () => {
  const [{ allowCookies }, setCookie] = useCookies(['allowCookies']);

  const cookiesConsent = () => {
    setCookie('allowCookies', true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 100,
    });
  };

  const cancelCookie = () => {
    setCookie('allowCookies', false, {
      path: '/',
      maxAge: 60 * 60 * 24 * 100,
    });
  };

  return (
    <Drawer
      className={styles.cookieBar}
      height={'auto'}
      destroyOnClose={true}
      footer={null}
      visible={!allowCookies}
      closable={false}
      placement="bottom"
    >
      <h3><span className="turquoise">Wij vinden jouw privacy belangrijk</span></h3>
      <p>
        Welkom! We gebruiken cookies op onze website om het gebruiksvriendelijker te maken voor jou.
        Cookies zijn eenvoudige (tijdelijke) tekstbestanden voor jouw pc.
        Dankzij cookies hoef je niet steeds dezelfde informatie in te voeren of te
        downloaden wanneer je bij ons terugkomt. Lekker handig! Wil je de beste website ervaring? Klik dan op "Akkoord".
      </p>
      <Button
        type="primary"
        onClick={cookiesConsent}
        style={{ marginRight: 20 }}
      >
        Akkoord
      </Button>
      <Button onClick={cancelCookie}>
        Annuleren
      </Button>
    </Drawer>
  );
};

export default CookieBar;
