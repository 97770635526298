import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import styles from './spotlight.module.css';
import SpotlightCard from './spotlightCard';
import { InhouseDayProps, TBEvent } from '../../utils';
import EventCard from '../eventCard/eventCard';

export interface SpotlightProps {
  title?: string;
  buttonLabel?: string;
  buttonLink?: string;
  imageUrl?: string;
  event?: TBEvent;
  activities?: InhouseDayProps[];
}

const Spotlight: FC<SpotlightProps> = ({ title, buttonLabel, buttonLink, imageUrl, event, activities }) => {
  return (
    <section className={styles.section}>
      <div className="container">
        <h2>{title}</h2>
        <Row gutter={60}>
          <Col xs={24} md={12}>
            {event && (
              <>
                <h3>
                  <span className="turquoise">Spotlight</span>
                </h3>
                <div className={styles.mobileOnly}>
                  <EventCard
                    date={event.date}
                    description="Op ons carrière evenement vind je bedrijven die bij jou passen."
                    image={event.banner.url}
                    location={event.location}
                    title={event.title}
                    linkLabel="Lees meer"
                    linkUrl="/event"
                  />
                </div>
                <div className={styles.tabletOnly}>
                  <SpotlightCard
                    date={event.date}
                    description="Op ons carrière evenement vind je bedrijven die bij jou passen."
                    imageUrl={event.banner.url}
                    location={event.location}
                    title={event.title}
                    url="/event"
                    cover
                  />
                </div>
              </>
            )}
            {activities?.length && <h3>Binnenkort</h3>}
            {activities?.map(activity => (
              <SpotlightCard
                key={activity.slug}
                date={activity.startDate}
                imageUrl={activity.company.logo.url}
                location={activity.location}
                title={activity.title}
                url={`/inhousedagen/${activity.slug}`}
              />
            ))}
            <a href={buttonLink}>{buttonLabel}</a>
          </Col>
          <Col xs={0} md={12}>
            <figure className={styles.droplet}>
              <svg viewBox="0 0 1 0.74018508" width="0" height="0">
                <clipPath id="dropletPath" clipPathUnits="objectBoundingBox">
                  <path d="M 0.98258707,0 C 0.24300697,0 0,0.23770746 0,0.50746268 0,0.77721791 0.18045993,0.9409011 0.45273632,0.94278607 0.61256066,0.94388957 0.825798,0.79248596 1,0.61940297 V 0.00248756 C 0.992621,0.00228343 0.990312,-4.3993643e-7 0.98258707,0 Z" />
                </clipPath>
              </svg>
              <img src={imageUrl} alt="" />
            </figure>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Spotlight;
