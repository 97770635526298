import { MailOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import styles from './photoList.module.css';
import linkedIn from '../../images/linkedin-letters.svg';

interface Photos {
  name: string,
  function: string,
  linkedInUrl: string,
  mailTo: string,
  institution: string,
  photo: {
    url: string,
  }
}

const Photo = ({ title, description, photos }: { title: string, description: string, photos: Photos }) => {
  return <div className={styles.photoList}>
    <div className="container">
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <Row gutter={[20, 20]}>
        {photos && photos.map((photo, i) => <Col key={i}>
          <div className={styles.person}>
            <img
              className={styles.photo}
              src={photo?.photo?.url} />
            <h2>{photo.name}</h2>
            <p>{photo.function}</p>
            {photo.institution ? <p><b>{photo.institution}</b></p>
              :
                <p>
                  <a href={`mailto:${photo.mailTo}`}>

                    <MailOutlined style={{ fontSize: 25 }} />
                  </a>
                  <a href={`${photo.linkedInUrl}`}>
                    <img src={linkedIn} className={styles.linkedInIcon} />
                  </a>
                </p>
            }
          </div>
        </Col>)}
      </Row>
    </div>
  </div>
};

export default Photo;
