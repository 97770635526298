import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Drawer } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';
import Logo from '../../images/logo.svg';
import { LoginButton } from '../';
import styles from './sideBar.module.css';
import { StrapiMenuItem, StrapiMenuGroup } from '../../utils';

const cx = classnames.bind(styles);

export interface SideBarProps {
  items: [StrapiMenuItem | StrapiMenuGroup],
  visible: boolean,
  setVisibility: Function,
};

const SideBar = ({ items, visible, setVisibility }: SideBarProps) => {
  const [expanded, setExpanded] = useState<string | null>();
  const toggleExpanded = (id: string) => {
    setExpanded(id === expanded ? null : id);
  };
  const onClose = () => setVisibility(false);

  return (
    <Drawer
      onClose={onClose}
      placement={'left'}
      className={styles.base}
      visible={visible}
      headerStyle={{
        border: 'none'
      }}
      footerStyle={{
        border: 'none'
      }}
      width={500}
      title={<img alt="Techniek Bedrijven" src={Logo} />}
      footer={<LoginButton responsive={false} />}
    >
      {items.map(item =>
        <React.Fragment key={`${item.__typename}-${item.id}`}>
          {item.__typename == 'STRAPI_ComponentMenuMenuItem' &&
            <div className={styles.item}>
              <Link
                to={item.url}
                onClick={onClose}
                className={styles.item}
                activeClassName={styles.active}
              >
                {item.label}
              </Link>
            </div>
          }
          {item.__typename == 'STRAPI_ComponentMenuGroup' &&
            <div className={styles.item}>
              <div
                onClick={() => toggleExpanded(item.id)}
                className={cx({ expanded: expanded === item.id })}
              >
                {item.label}
                <CaretDownOutlined />
              </div>
              {expanded === item.id &&
                <div className={`${styles.subItems}`}>
                  {item.children.map(child =>
                    <Link
                      to={child.url}
                      activeClassName={styles.active}
                      key={`item-${item.id}-${child.id}`}
                    >
                      {child.label}
                    </Link>
                  )}
                </div>
              }
            </div>
          }
        </React.Fragment>
      )}
    </Drawer>
  );
};

export default SideBar;
