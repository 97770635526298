import { ApolloError } from '@apollo/client';

export const extractGraphqlErrorMessages = (error: ApolloError) => (
  error.graphQLErrors.reduce((obj, err) => {
    if (!err.path) return obj;

    const exceptionMessage = err.extensions?.exception?.data?.message;
    const errorObjects = Array.isArray(exceptionMessage) ?
      exceptionMessage.map(m => m.messages).flat() :
      exceptionMessage ? [exceptionMessage] : [{ message: err.message }];
    const codes = errorObjects.map(o => o.Code || o.id);
    const messages = errorObjects.map(o => o.Message || o.message);

    return {
      ...obj,
      [err.path.toString()]: { codes, messages },
    };
  }, {
    _exception: error.graphQLErrors,
  })
);

export const validateOnce = (errors: string[], setErrors: Function, key: string, message: string) => ({
  validator: () => {
    if (errors.includes(key)) {
      setErrors(errors.filter(e => e !== key));
      return Promise.reject(message);
    } else {
      return Promise.resolve();
    }
  }
});
