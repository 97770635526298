import React from 'react';
import { Button, Col, Row } from 'antd';
import { Color } from '../../utils';
import styles from './dropletSection.module.css';
import ReactMarkdown from 'react-markdown';

export interface DropletSectionProps {
  title: string,
  subTitle: string,
  paragraph: string,
  buttonLabel: string,
  buttonLink: string,
  imageUrl: string,
  color: Color,
  alignment: string,
};

const DropletSection = ({ title, subTitle, paragraph, buttonLabel, buttonLink, imageUrl, color, alignment }: DropletSectionProps) => {
  return (
    <article className={`${styles.section} ${alignment}`}>
      <Row gutter={60}>
        <Col md={12} order={alignment === 'right' ? 1 : 2}>
          <h3><span className={color}>{title}</span></h3>
          <h4>{subTitle}</h4>
          <ReactMarkdown source={paragraph} />
          <Button
            href={buttonLink}
            className={`btn-shadow ${color}`}
          >
            {buttonLabel}
          </Button>
        </Col>
        <Col md={12} order={alignment === 'right' ? 2 : 1}>
          <figure className={`${color} ${alignment}`}>
            <img src={imageUrl} alt="" />
          </figure>
        </Col>
      </Row>
    </article>
  );
};

export default DropletSection;
