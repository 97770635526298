export const minArrayLength = (length: number, array: Array<any>): Array<any> => {
  if (array.length >= length) return array;
  return minArrayLength(length, array.concat(array));
}

export const maxArrayLength = (length: number, array: Array<any>): Array<any> => {
  return array.slice(0, length);
}

export const exactArrayLength = (length: number, array: Array<any>): Array<any> => {
  return maxArrayLength(length, minArrayLength(length, array));
}

export const evenArrayLength = (array: Array<any>): Array<any> => {
  if (array.length % 2 === 0) return array;
  return array.concat(array[Math.floor(array.length / 2)]);
}
