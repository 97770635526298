import { Button, Col, Row } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import arrow from '../../images/arrow.svg';
import close from '../../images/close.svg';
import styles from './pickerHeader.module.css';

const PreEventsPicker = () => {
  return <div className={styles.header}>
    <div className="container-sm">
      <Row>
        <Col flex="auto">
          <Link to="/student/account/">
            <img className={styles.backArrow} src={arrow} alt="back-arrow" />
          </Link>
        </Col>
        <Col flex="34px">
          <Button size="small" type="link">
            <Link to="/student/programma/">
              <img className={styles.close} src={close} alt="close-x" />
            </Link>
          </Button>
        </Col>
      </Row>
    </div>
  </div>
};

export default PreEventsPicker;
