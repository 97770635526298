import React from 'react';
import { UserBoard } from '../../../utils';
import ItemsModal, { ItemsModalProps } from '../../formComponents/itemsModal';
import { Form, Input, Radio, Row, Col } from 'antd';

const options = [
  {label: 'Studentenvereniging', value: 'STUDENT_ASSOCIATION' },
  {label: 'Studievereniging', value: 'STUDY_ASSOCIATION' },
  {label: 'Stichting', value: 'FOUNDATION' },
  {label: 'Organisatie', value: 'ORGANIZATION' },
  {label: 'Dispuut', value: 'DISPUTATION' },
  {label: 'Masterbestuur', value: 'MASTER_BOARD' },
  {label: 'Verenigingsbestuur', value: 'OTHER_ASSOCIATION' },
];

const BoardsModal = (modalProps: ItemsModalProps<Partial<UserBoard>>) => {
  const Modal = ItemsModal<Partial<UserBoard>>();

  return (
    <Modal
      {...modalProps}
    >
      <h3><span className="turquoise">Bestuur</span></h3>

      <Form.Item
        label="Waar heb je bestuur gedaan?"
        name="type"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            {options.map((board, i) => <Col key={i} lg={12}>
              <Radio value={board.value}>{board.label}</Radio>
            </Col>)}
          </Row>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Naam organisatie"
        name="name"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Welke functie had je hier?"
        name="position"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Dit was::"
        name="employmentType"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            <Col lg={12}>
              <Radio value="PARTTIME">Parttime</Radio>
            </Col>
            <Col lg={12}>
              <Radio value="FULLTIME">Fulltime</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

    </Modal>
  )
}

export default BoardsModal;
