import React from 'react';
import { Row, Col, Grid } from 'antd';
import { replaceMatches } from '../../utils';
import check from '../../images/check.svg';
import styles from './timeline.module.css';
import ReactMarkdown from 'react-markdown';

interface TimelineProps {
  title?: string,
  steps: Array<{
    title: string,
    description: string,
  }>,
  conclusion?: string;
  forceVertical?: boolean,
  version: string,
};

const Timeline = ({ title, steps, conclusion, forceVertical, version }: TimelineProps) => {
  const { useBreakpoint } = Grid;
  const smallScreen = !useBreakpoint().lg;
  const direction = forceVertical || smallScreen ? 'vertical' : 'horizontal';

  const replacedSteps = steps.map(step => {
    return Object.assign({},
      ...Object.entries(step).map(([key, value]) => {
        if (typeof value !== "string") return { [key]: value };
        return { [key]: value.replace(/{(\w*)}/g, (_, match) => replaceMatches(match)) };
      }),
    );
  });

  return (
    <section className={`${styles.horizontal} ${styles[version]} ${styles[direction]}`}>
      <div className={forceVertical ? 'container-sm' : 'container'}>
        <h2>{title}</h2>
        <Row>
          {replacedSteps.map((step, i) => (
            <Col
              xs={24}
              lg={direction === 'vertical' ? 24 : 8}
              key={i}
              className={styles.item}
            >
              <h4 className="getBgByBlock">{step.title}</h4>
              {version === 'signup' && step.check && <img src={check} alt="check" />}
              <p>{step.description}</p>
            </Col>
          ))}
        </Row>
        <div className={styles.conclusion}>
          <ReactMarkdown source={conclusion} />
        </div>
      </div>
    </section>
  )
};

export default Timeline;
