import React from 'react';
import { Button } from 'antd';
import styles from './callToAction.module.css';
import { ButtonType, Color } from '../../utils';

interface CallToActionProps {
  title?: string,
  color?: Color,
  subTitle?: string,
  buttonLabel?: string,
  buttonLink?: string,
  buttonType?: ButtonType | 'underline',
};

const CallToAction = ({ title, color, subTitle, buttonLabel, buttonLink, buttonType = 'primary' }: CallToActionProps) => {
  return (
    <section className={styles.callToAction}>
      <div className="container">
        {title && <h3><span className={color?.toString()}>{title}</span></h3>}
        {subTitle && <p>{subTitle}</p>}
        {
          buttonType === 'underline' ? (
            <a href={buttonLink}>{buttonLabel}</a>
          ) : (
            <Button type={buttonType}>
              <a href={buttonLink}>{buttonLabel}</a>
            </Button>
          )
        }
      </div>
    </section>
  )
};

export default CallToAction;
