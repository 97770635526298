import React from 'react';
import { UserJob } from '../../../utils';
import ItemsModal, { ItemsModalProps } from '../../formComponents/itemsModal';
import { Checkbox, Form, Input, Radio, Row, Col } from 'antd';
import ConfirmItem from '../../formComponents/confirmItem';

const JobsModal = (modalProps: ItemsModalProps<Partial<UserJob>>) => {
  const Modal = ItemsModal<Partial<UserJob>>();

  return (
    <Modal
      {...modalProps}
    >
      <h3><span className="turquoise">Werkervaring toevoegen</span></h3>
      <Form.Item
        label="Geef de naam van het bedrijf op"
        name="companyName"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Wat is de functienaam?"
        name="jobTitle"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Het gaat om een"
        name="type"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            <Col xs={12} flex="0 1 150px">
              <Radio value="SIDEJOB">(Bij)baan</Radio>
            </Col>
            <Col xs={12} flex="0 1 150px">
              <Radio value="INTERNSHIP">Stage</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

      <ConfirmItem title="Deze ervaring was (deels) in het buitenland">
        <Form.Item name="partiallyAbroad" valuePropName="checked" noStyle>
          <Checkbox />
        </Form.Item>
      </ConfirmItem>

    </Modal>
  )
}

export default JobsModal;
