import React from 'react';
import { Form, Button } from "antd";
import { FormItemProps } from "antd/lib/form";
import { PlusOutlined } from "@ant-design/icons";
import { useBackendMutation } from '../../hooks/useBackendMutation';
import { gql } from '@apollo/client';
import { useState, useEffect } from 'react';

export interface ItemsOverviewProps {
  field: string,
  name: { singular: string, plural: string }
  shouldUpdate: FormItemProps['shouldUpdate']
  ItemComponent: any,
  openEdit: any,
  openNew: any,
  destroyItem: any,
}

const ItemsOverview = ({ field, name, shouldUpdate, ItemComponent, openEdit, openNew, destroyItem }: ItemsOverviewProps) => {
  const [initialItemCount, setInitialItemCount] = useState(0);

  const initOriginalCount = (items: any) => {
    if (initialItemCount === 0) {
      setInitialItemCount(items.length);
    }
  }

  return (
    <>
      <Form.Item
        label={`Toegevoegde ${name.plural}`}
        shouldUpdate={shouldUpdate}
      >
        {({ getFieldValue }) => {
          const items = getFieldValue(field) || [];

          initOriginalCount(items);

          return items.length ? (
            items.map((item: any, i: number) => (
              <ItemComponent
                key={i}
                item={item}
                onClick={() => openEdit(i, item)}
                destroyItem={() => destroyItem(i)}
              />
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>Je hebt nog geen {name.plural} toegevoegd</p>
          )
        }}
      </Form.Item>
      <Form.Item shouldUpdate={shouldUpdate}>
        {({ getFieldValue }) => {

          return (
            <Button
              block
              type={getFieldValue(field)?.length ? 'default' : 'primary'}
              onClick={openNew}
            >
              <PlusOutlined /> {name.singular} toevoegen
            </Button>
          );
        }}
      </Form.Item>
      <Form.Item shouldUpdate={shouldUpdate}>
        {({ getFieldValue }) => (
          (getFieldValue(field)?.length > 0 || initialItemCount > 0) ? (
            <Button
              block
              htmlType="submit"
              type="primary"
            >
              Opslaan
            </Button>
          ) : null
        )}
      </Form.Item>
    </>
  );
}

export default ItemsOverview
