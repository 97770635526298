import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { useLocation } from '@reach/router';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks';
import { LoginButton } from '../';
import SideBar from './sideBar';
import { StrapiMenu } from '../../utils';
import styles from './header.module.css';
import Logo from '../../images/logo.svg';
import LogoCircle from '../../images/logo-circle.svg';

const cx = classnames.bind(styles);

interface QueryResult {
  strapi: {
    menu: StrapiMenu,
  },
};

const Header = () => {
  const { me, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [sideBarVisible, setSideBarVisibility] = useState(false);
  const { strapi: { menu } }: QueryResult = useStaticQuery(graphql`
    query {
      strapi {
        menu {
        items {
          __typename
          ... on STRAPI_ComponentMenuGroup {
            id
            label
            children {
              id
              label
              url
            }
          }
          ... on STRAPI_ComponentMenuMenuItem {
            id
            label
            url
          }
        }
      }
    }
  }
  `);

  return (
    <header className={styles.base}>
      <div className={`container ${styles.container}`}>
        <h1>
          <Link to="/">
            <img src={Logo} alt="Techniek Bedrijven" />
          </Link>
        </h1>
        <MenuOutlined className={styles.menuSmall} onClick={() => setSideBarVisibility(true)} />
        <div className={styles.centerLogo}>
          <Link to="/" className={styles.emblem}>
            <img src={LogoCircle} alt="Techniek Bedrijven"  />
          </Link>
        </div>
        {menu.items.map(item =>
          <React.Fragment key={`${item.__typename}-${item.id}`}>
            {item.__typename == 'STRAPI_ComponentMenuMenuItem' &&
              <Link
                to={item.url}
                className={styles.navLinkDesktop}
                activeClassName="active"
              >
                {item.label}
              </Link>
            }
            {item.__typename == 'STRAPI_ComponentMenuGroup' &&
              <div
                className={cx('navLinkDesktop', { active: item.children.some(child => child.url === pathname) })}
              >
                {item.label}
                <div className={styles.hoverHelper}>
                  <div className={styles.navChild}>
                    {item.children.map(child =>
                      <Link key={`item-${item.id}-${child.id}`} to={child.url}>
                        {child.label}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            }
          </React.Fragment>
        )}
        <div>
          { isAuthenticated ? <>
              <Link to="/student/account/" className={styles.profileButton}>
                <UserOutlined style={{ fontSize: 25 }} />
                <span>{me.firstName}</span>
              </Link>
            </>
          :
            <LoginButton responsive={true} />
          }
        </div>
      </div>
      <SideBar
        items={menu.items}
        visible={sideBarVisible}
        setVisibility={setSideBarVisibility}
      />
    </header>
  );
};

export default Header;
