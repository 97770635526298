import React from 'react';
import VideoEmbed, { VideoEmbedProps } from './videoEmbed';
import styles from './videoEmbedSection.module.css';

const VideoEmbedSection = ({ title, embedCode, thumbnailUrl, width, height }: VideoEmbedProps) => {
  return (
    <section className={styles.videoEmbedSection}>
      <div className="container">
        <VideoEmbed
          title={title}
          embedCode={embedCode}
          thumbnailUrl={thumbnailUrl}
          width={width}
          height={height}
        />
      </div>
    </section>
  )
};

export default VideoEmbedSection;
