import { createContext, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { replaceMatches } from '../utils';

export type MicrocopyKey = 'EventPageTitle' | 'EventNotReady'
  | 'CompaniesEstablishedTitle' | 'CompaniesEstablishedDescription' | 'CompaniesEstablishedButtonLabel'
  | 'EventsYourFavoriteProgramItems' | 'EventsNoFavoriteProgramItemsPicked'
  | 'FavoriteProgramItemsPickedTitle' | 'FavoriteProgramItemsPickedDescription' | 'FavoriteProgramItemsPickedSub'
  | 'MatchesReadyTitle' | 'MatchesReadyDescription' | 'MatchesReadyButtonLabel' | 'MatchesReadyCancelLink'
  | 'ParticipationConfirmedTitle' | 'ParticipationConfirmedDescription'
  | 'UnsubscribeReceivedTitle' | 'UnsubscribeReceivedDescription'
  | 'NoMatchPossibleTitle' | 'NoMatchPossibleDescription' | 'NoMatchPossibleButtonLabel'
  | 'InhouseDayTitle' | 'InhouseDayDescription'
  | 'InhouseDaySelectedTitle' | 'InhouseDaySelectedDescription' | 'InhouseDayNotSelectedTitle' | 'InhouseDayNotSelectedDescription'
  | 'InhouseDaySendCvTitle' | 'InhouseDaySendCvDescription' | 'InhouseDayClosedTitle' | 'InhouseDayClosedDescription'
  | 'HeroYellowAreaSignup'


export type Microcopy = { [key in MicrocopyKey]?: string }

const MICROCOPY_QUERY = graphql`
  query {
    strapi {
      microcopy {
        EventPageTitle
        EventNotReady
        CompaniesEstablishedTitle
        CompaniesEstablishedDescription
        CompaniesEstablishedButtonLabel
        EventsYourFavoriteProgramItems
        EventsNoFavoriteProgramItemsPicked
        FavoriteProgramItemsPickedTitle
        FavoriteProgramItemsPickedDescription
        FavoriteProgramItemsPickedSub
        MatchesReadyTitle
        MatchesReadyDescription
        MatchesReadyButtonLabel
        MatchesReadyCancelLink
        ParticipationConfirmedTitle
        ParticipationConfirmedDescription
        UnsubscribeReceivedTitle
        UnsubscribeReceivedDescription
        NoMatchPossibleTitle
        NoMatchPossibleDescription
        NoMatchPossibleButtonLabel
        InhouseDayTitle
        InhouseDayDescription
        InhouseDaySelectedTitle
        InhouseDaySelectedDescription
        InhouseDayNotSelectedTitle
        InhouseDayNotSelectedDescription
        InhouseDaySendCvTitle
        InhouseDaySendCvDescription
        InhouseDayClosedTitle
        InhouseDayClosedDescription
        HeroYellowAreaSignup
      }
    }
  }
`

export const MicrocopyContext = createContext<Microcopy>({});

const useMicrocopy = () => {
  const dictonary = useContext(MicrocopyContext);
  return (key: MicrocopyKey) => dictonary[key];
}

export const useFetchMicrocopy = () => {
  const { strapi } = useStaticQuery<{ strapi: { microcopy: Microcopy } }>(MICROCOPY_QUERY);

  return Object.assign({},
    ...Object.entries(strapi.microcopy).map(([key, value]) => {
      if (value) return {[key]: value.replace(/{(\w*)}/g, (_, match) => replaceMatches(match))};
      return {};
    }),
  );
}

export default useMicrocopy;
