import { Form } from "antd";
import { useState } from "react";

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

const replace = <T>(list: T[], i: number, item: T) => {
  return [
    ...list.slice(0, i),
    item,
    ...list.slice(i + 1)
  ];
}

const useLinkedForms = <T extends readonly unknown[]>(field: string, onFinish: Function) => {
  const [form] = Form.useForm();
  const [linkedForm, setLinkedForm] = useState<{ i: number, item: ArrayElement<T>} | undefined>(undefined);

  const destroyItem = (i: number) => {
    let items = form.getFieldValue(field) || [];
    items.splice(i, 1);
    form.setFieldsValue({ [field]: items });
  }

  const onSubFormSubmit = ({ values: item }: any) => {
    const items = form.getFieldValue(field) || [];

    if (!linkedForm || linkedForm.i === -1) {
      form.setFieldsValue({ [field]: [...items, item] });
    } else {
      form.setFieldsValue({ [field]: replace(items, linkedForm.i, item) });
    }
    setLinkedForm(undefined);
  }

  const onFormSubmit = () => {
    const items = form.getFieldValue(field);
    onFinish({ [field]: items });
  }

  const onFormFinish = (name: string, info: any) => {
    if (name === 'linkedForm') {
      onSubFormSubmit(info);
    } else {
      onFormSubmit();
    }
  };

  const shouldUpdate = (prevValues: any, curValues: any) => {
    return JSON.stringify(prevValues[field]) === JSON.stringify(curValues[field]);
  };

  return {
    form: { form, name: 'parentForm' },
    linkedForm: {
      value: linkedForm,
      name: 'linkedForm',
      visible: linkedForm !== undefined,
      onCancel: () => { setLinkedForm(undefined) }
    },
    onFormFinish,
    shouldUpdate,
    openEdit: (i: number, item: ArrayElement<T>) => setLinkedForm({ i, item }),
    openNew: (defaultValue: any = {}) => setLinkedForm({ i: -1, item: defaultValue }),
    destroyItem: destroyItem,
  }
}

export default useLinkedForms;
