import React from 'react';
import Slider from "react-slick";
import { minArrayLength } from '../../utils';
import styles from './testimonials.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface TestimonialsProps {
  title: string,
  testimonials: Array<{
    id: string,
    imageUrl: string,
    excerpt: string,
    quote: string,
    study?: string,
    age?: number,
    name: string,
  }>,
};

const Testimonials = ({ testimonials, title }: TestimonialsProps) => {
  const testimonialsList = minArrayLength(5, testimonials);

  return (
    <section className={styles.testimonials}>
      <div className="container">
        <h2>{title}</h2>
        <Slider
          arrows={false}
          dots
          infinite
          autoplay
          autoplaySpeed={4000}
          speed={1500}
          slidesToShow={3}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                autoplaySpeed: 3000,
              }
            }
          ]}
        >
          {testimonialsList.map((t, i) =>
            <div key={i} className={styles.item}>
              <div className={styles.top}>
                <span>&ldquo;</span>
                {t.excerpt}
                <span>&ldquo;</span>
              </div>
              <figure className={styles.image}>
                <img src={t.imageUrl} alt={t.name} />
              </figure>
              <div className={styles.bottom}>
                <p>{t.name} - {t.age} jaar</p>
                <p>{t.study}</p>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;

