import React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import { minArrayLength, evenArrayLength } from '../../utils';
import styles from './logoBar.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface LogoBarProps {
  logos: Array<{
    imageUrl: string,
    link: string,
  }>,
};

const LogoBar = ({ logos }: LogoBarProps) => {
  const logoList = evenArrayLength(minArrayLength(8, logos));

  return (
    <section className={styles.logoBar}>
      <div className="container">
        <Slider
          arrows={false}
          autoplay
          autoplaySpeed={4000}
          speed={1000}
          slidesToShow={6}
          infinite={true}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                rows: 2,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 2,
                rows: 2,
              }
            }
          ]}
        >
          {logoList.map((logo, i) =>
            <div key={i} className={styles.slide}>
              <a href={logo.link}>
                <img
                  className={styles.logo}
                  src={logo.imageUrl}
                />
              </a>
            </div>
          )}
        </Slider>
        <Link to="/bedrijven/" >
          Bekijk alle bedrijven
        </Link>
      </div>
    </section>
  );
};

export default LogoBar;
