import { createContext, useContext } from 'react';

interface ModalContext {
  modal: null | string
  setActiveModal: Function
  onFinish: null | Function
  onCancel: null | Function
}

export const ModalContext = createContext<ModalContext>({
  modal: null,
  setActiveModal: () => {},
  onFinish: null,
  onCancel: null,
});

const useActiveModal = () => useContext(ModalContext);

export default useActiveModal;
