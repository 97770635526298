import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useClient } from './apollo/client';
import { MicrocopyContext, useFetchMicrocopy } from './hooks/useMicrocopy';
import { ModalContext } from './hooks/useActiveModal';
import { CookieBar, LoginModal, RegisterModal, ConfirmSentModal, ForgotPasswordModal, Gtm, EnrollModal } from './components';
import { useHashParam } from './hooks';
import moment from 'moment';
import 'moment/locale/nl';

const App = ({ children }: { children: React.ReactNode }) => {
  const microcopy = useFetchMicrocopy();

  const [modal, setModal] = useState<string | null>(null);
  const [onFinish, setOnFinish] = useState<Function | null>(null);
  const [onCancel, setOnCancel] = useState<Function | null>(null);

  if (typeof window !== "undefined") {
    const [activeModel] = useHashParam('active-modal', '');
    useEffect(() => {
      setActiveModal(activeModel)
    }, [activeModel]);
  }

  moment.locale('nl');

  const setActiveModal = (modal: string | null) => {
    return new Promise<void>((resolve, reject) => {
      setModal(modal);
      setOnFinish(() => () => {
        setActiveModal(null);
        resolve();
      });
      setOnCancel(() => () => {
        setActiveModal(null);
        reject()
      });
    });
  }

  const client = useClient({ setActiveModal });

  return (
    <ApolloProvider client={client}>
      <MicrocopyContext.Provider value={microcopy}>
        <ModalContext.Provider value={{ modal, setActiveModal, onFinish, onCancel }}>
          <LoginModal />
          <ForgotPasswordModal />
          <RegisterModal />
          <ConfirmSentModal />
          <CookieBar />
          <Gtm />
          <EnrollModal />
          {children}
        </ModalContext.Provider>
      </MicrocopyContext.Provider>
    </ApolloProvider>
  );
};

const wrapRootElement = ({ element }: { element: React.ReactNode }) => <App>{element}</App>;

export default wrapRootElement;
