import React, { useState } from 'react';
import { Form, Button, Input, Row, Col, Radio } from 'antd';
import { LayoutWizard, PickerHeader } from '../';
import { useAuth, useFormFieldProvider } from '../../hooks';
import { navigate } from '@reach/router';
import { gql, useQuery } from '@apollo/client';
import formStyles from '../formComponents/formComponents.module.css';
import styles from './cancel.module.css';
import { useBackendMutation } from '../../hooks/useBackendMutation';

interface reason {
  id: string,
  label: string,
};

const CANCEL_REASONS = gql`
  query {
    cancelReasons {
      id
      label
    }
  }
`;

const Cancel = () => {
  const { updateMe } = useAuth();
  const [finalReason, setFinalReason] = useState('');
  const { data } = useQuery(CANCEL_REASONS);
  const [updateParticipation] = useBackendMutation(gql`
    mutation updateParticipation($participation: String!) {
      updateParticipation(participation: $participation)
    }
  `);

  const [form] = Form.useForm();
  const { onFieldsChange, useFormField } = useFormFieldProvider(form);
  const [cancelReason] = useFormField(['cancelReason']);

  const setCancelReason = (e) => {
    setFinalReason(e.target.value);
    form.setFieldsValue({ custom: '' });
  };

  const setCustom = (e) => {
    setFinalReason(e.target.value);
    form.setFieldsValue({ cancelReason: 'custom' });
  };

  const submit = async () => {
    await updateMe({
      variables: {
        input: {
          cancelReason: finalReason,
        },
      },
    });
    await updateParticipation({
      variables: {
        participation: 'DENIED',
      },
    });
    navigate('/student/deelname-annuleren-compleet/');
  };

  return (
    <LayoutWizard>
      <PickerHeader />
      <div className="container-sm">
        <h3>
          <span className="turquoise">Jammer dat je toch niet kunt!</span>
        </h3>
        <p>Geef hieronder aan waarom je niet meer komt.</p>

        <Form className={formStyles.form} layout="vertical" form={form} onFieldsChange={onFieldsChange}>
          <Form.Item name="cancelReason">
            <Radio.Group onChange={setCancelReason}>
              <Row gutter={20}>
                <Col lg={12}>
                  {data &&
                    data.cancelReasons.map((reason: reason) => (
                      <Radio value={reason.label} key={reason.id}>
                        {reason.label}
                      </Radio>
                    ))}
                  <Radio value="custom">Om een andere reden, namelijk</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>

          {cancelReason === 'custom' && (
            <Form.Item className={styles.whiteTextarea} name="custom">
              <Input.TextArea onChange={setCustom} placeholder="Geef hier een toelichting" />
            </Form.Item>
          )}

          <Form.Item style={{ marginTop: '40px' }}>
            <Button type="primary" block htmlType="submit" onClick={submit}>
              Verstuur
            </Button>
          </Form.Item>
        </Form>
      </div>
    </LayoutWizard>
  );
};

export default Cancel;
