import React, { useEffect } from 'react';
import {
  Form, Modal, Button, Input, Checkbox, InputNumber, Radio, Row, Col, DatePicker,
} from 'antd';
import { useFormFieldProvider } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';

export interface StudentTeamModalProps {
  name: string,
  visible: boolean,
  value?: {
    i: number,
    item: object,
  },
  onCancel: () => void,
}

const StudentTeamModal = ({
  name, visible, value, onCancel,
}: StudentTeamModalProps) => {
  const [form] = Form.useForm();
  const { onFieldsChange, setFieldsValue } = useFormFieldProvider(form);

  useEffect(() => {
    if (!visible || !value?.item) {
      form.resetFields();
    } else if (value) {
      setFieldsValue(value.item);
    }
  }, [visible]);

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-900)' }}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Form className={formStyles.form} form={form} name={name} onFieldsChange={onFieldsChange}>
        <h3>
          <span className="turquoise">Studententeam</span>
        </h3>

        <p className="infoBlock">Initiatieven in samenwerking met de universiteit zoals Dreamteam, Solar Team etc.</p>

        <Form.Item
          label="Naam studententeam"
          name="name"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Welke functie had je hier?"
          name="position"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="In welk dienstverband was dit?"
          name="employmentType"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <Radio.Group className="checkbox-normal">
            <Row gutter={10}>
              <Col lg={12}>
                <Radio value="PARTTIME">Parttime</Radio>
              </Col>
              <Col lg={12}>
                <Radio value="FULLTIME">Fulltime</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>

        <Row gutter={10}>
          <Col xs={12}>
            <Form.Item
              label="Begin datum"
              name="beginDate"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <DatePicker className="datepickerStyle" format="MMMM YYYY" picker="month" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Eind datum (verwacht)"
              name="endDate"
              rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
            >
              <DatePicker className="datepickerStyle" format="MMMM YYYY" picker="month" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Voor hoeveel uur per week (gemiddeld)?"
          name="hoursPerWeek"
          rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
          <InputNumber type="number" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Volgende
          </Button>
        </Form.Item>

        <Form.Item>
          <Button block htmlType="submit">
            Annuleren
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StudentTeamModal;
