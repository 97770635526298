import React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import StudyItem from '../../formComponents/studyItem';
import StudiesModal from './studiesModal';
import { pick } from '../../../utils';
import { useStep } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';
import useLinkedForms from '../../../hooks/useLinkedForms';
import ItemsOverview from '../../formComponents/itemsOverview';

interface FormData {
  studies: any[]
}

const Studies = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    me => ({
      studies: (me?.studies || []).map(study => ({
        ...pick(study, [
          'id',
          'type',
          'educationName',
          'educationalInstitutionName',
          'minor',
          'finished',
          'nominal',
          'monthsBehind',
          'numberOfECs',
          'partiallyAbroad',
          'averageMark',
        ]),
        educationalInstitution: study.educationalInstitution?.id,
        education: study.education?.id,
        beginDate: study.beginDate ? moment(study.beginDate) : undefined,
        endDate: study.endDate ? moment(study.endDate) : undefined,
      })),
    }),
    ({ studies }) => ({
      studies: studies.map((study: any) => ({
        ...study,
        beginDate: study.beginDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        endDate: study.endDate?.format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
        averageMark: Number.parseFloat(study.averageMark),
        numberOfECs: Number.parseFloat(study.numberOfECs),
        monthsBehind: Number.parseFloat(study.monthsBehind),
      })),
    })
  );

  const {
    form,
    linkedForm,
    onFormFinish,
    openEdit,
    openNew,
    shouldUpdate,
    destroyItem,
  } = useLinkedForms<FormData['studies']>('studies', onFinish)

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Form {...form} className={formStyles.form} initialValues={initialValues}>
        <h3>
          <span className="turquoise">Studies</span>
        </h3>
        <p>
          Voeg hieronder al je studies toe, zowel je huidige als je afgeronde studies.
        </p>

        <ItemsOverview
          field="studies"
          name={{ singular: 'Studie', plural: 'studies' }}
          ItemComponent={StudyItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />
      </Form>

      <StudiesModal {...linkedForm} />
    </Form.Provider>
  );
}

export default Studies;
