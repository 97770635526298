import React from 'react';
import { Progress } from 'antd';
import styles from './progressBar.module.css';

const ProgressBar = ({ progress }: { progress: number }) => {
  const color = progress > 49 ? '#00ff7e' : '#ffac00';

  return (
    <div className={styles.base}>
      <Progress
        strokeWidth={16} // this width is the height of the colored stroke/progress
        percent={progress}
        strokeColor={color}
        status={'normal'}
      />
    </div>
  );
};

export default ProgressBar;
