import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { Alert, Form, Input, Button, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useMutation, gql, ApolloError } from '@apollo/client';
import qs from 'qs';
import { Layout } from '../';
import { useAuth } from '../../hooks';
import { extractGraphqlErrorMessages, validateOnce } from '../../utils';
import formStyles from '../formComponents/formComponents.module.css'
import { useBackendMutation } from '../../hooks/useBackendMutation';

interface FormData {
  password: string;
};

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $code: String!) {
    resetPassword(password: $password, code: $code) {
      jwt
    }
  }
`;

const ResetPassword = () => {
  const { isAuthenticated, setToken } = useAuth();
  if (isAuthenticated) navigate('/student/profiel');

  const [resetPassword, { loading }] = useBackendMutation(RESET_PASSWORD);
  const [errors, setErrors] = useState([] as any);
  const [form] = Form.useForm();

  const location = useLocation();
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const onSubmit = async ({ password }: FormData) => {
    try {
      const { data } = await resetPassword({ variables: { password, code } });
      setToken(data.resetPassword.jwt);
    } catch (exception) {
      const error = extractGraphqlErrorMessages(exception as ApolloError);
      error.resetPassword ? setErrors(error.resetPassword.messages) : setErrors(['other']);
      form.validateFields();
    }
  };

  return (
    <Layout>
      <section style={{ padding: '32px 0' }}>
        <div className="container">
          <Row>
            <Col md={{ span: 12, offset: 6 }}>
              <h2>Wachtwoord resetten</h2>
              <h3><span className="turquoise">Kies een veilig wachtwoord</span></h3>
              <Form
                className={formStyles.form}
                layout="vertical"
                form={form}
                onFinish={onSubmit}
              >
                {errors.includes('other') && <Alert type="error" message="Er is iets misgegaan bij het wijzigen van je wachtwoord." showIcon icon={<WarningOutlined />} /> }
                {errors.includes('Invalid token') && <Alert type="error" message="Deze resetlink is ongeldig." showIcon icon={<WarningOutlined />} /> }
                <Form.Item
                  name="password"
                  label="Kies een wachtwoord"
                  rules={[
                    { required: true, message: 'Kies een wachtwoord' },
                    { pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{12,}$/, message: 'Je wachtwoord is niet complex genoeg' },
                    validateOnce(errors, setErrors, 'Auth.form.error.user.not-exist', 'Er is geen account met dit e-mailadres'),
                  ]}
                >
                  <Input.Password placeholder="Wachtwoord" />
                </Form.Item>
                <p style={{ fontSize: '14px', margin: '0 8px' }}>Je wachtwoord dient minimaal 12 karakters te bevatten waarvan klein en grote letters, en tenminste één cijfer en speciaal teken.</p>

                <Form.Item style={{ marginTop: '40px' }}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Wachtwoord instellen
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
