import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Card, Space } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import styles from './eventCard.module.css';

interface EventCardProps {
  image?: string;
  title?: string;
  description?: string;
  linkUrl?: string;
  linkLabel?: string;
  date?: Date;
  location?: string;
}

const EventCard: FC<EventCardProps> = ({ image, title, date, location, description, linkUrl, linkLabel }) => {
  return (
    <Card className={styles.card} bordered={false} cover={<img className={styles.img} src={image} />}>
      <Space direction="vertical">
        <b>{title}</b>
        <Space direction="horizontal" size={8}>
          <CalendarOutlined className={styles.icon} />
          <span>
            {moment(date).format('DD MMM YYYY')} - {location}
          </span>
        </Space>
        {description}
        <Link className={styles.link} to={linkUrl || ''}>
          {linkLabel}
        </Link>
      </Space>
    </Card>
  );
};

export default EventCard;
