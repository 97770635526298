import { useState, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';

export default (form: FormInstance) => {
  const [formFields, setFormFields] = useState([]);
  const onFieldsChange = (changedFields) => setFormFields(changedFields);

  const useFormField = (namePath: Array<string | number>) => {
    const initialValue = form.getFieldValue(namePath);
    const [field, setFieldState] = useState(initialValue);

    useEffect(() => {
      const changedField = formFields.find(({ name }) => JSON.stringify(name) === JSON.stringify(namePath));
      if (changedField) setFieldState(changedField.value);
    }, [formFields]);

    const setField = (value) => {
      if (typeof namePath === 'string') {
        return form.setFieldsValue({ [namePath]: value });
      } else if (Array.isArray(namePath) && namePath.length === 3) {
        const [parentName, index, name] = namePath;
        const parent = form.getFieldValue(parentName);
        if (!parent) return;
        const newValue = parent.map((item, i) => {
          if (i === index) {
            return { ...item, [name]: value }
          }
          return item;
        })
        form.setFieldsValue({ [parentName]: newValue });
      } else {
        throw Error('not supported')
      }
    }

    return [field, setField];
  }

  const setFieldsValue = (values: any) => {
    form.setFieldsValue(values);
    onFieldsChange(Object.entries(values).map(([name, value]) => ({
      name: [name],
      value,
    })));
  };

  return { onFieldsChange, useFormField, setFieldsValue };
}

