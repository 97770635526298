import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Button } from 'antd';
import { ProgressBar } from '..';
import { LayoutWizard } from '../';
import { useSteps } from '../../hooks';
import arrow from '../../images/arrow.svg';
import close from '../../images/close.svg';
import styles from './profileWizard.module.css';

type ProfileWizardProps = {
  '*'?: string;
}

const ProfileWizardView = (props: ProfileWizardProps) => {
  const { loading, progress, hasStep, goToStep, currentStep } = useSteps(props['*']);

  return (
    <LayoutWizard>
      <header className={styles.header}>
        <div className={styles.signupContainer}>
          <Row justify="space-between" gutter={32}>
            <Col flex="64px">
              {hasStep(-1) && (
                <Button size="small" type="link" onClick={() => goToStep(-1)}>
                  <img className={styles.backArrow} src={arrow} alt="back-arrow" />
                </Button>
              )}
            </Col>
            <Col flex="1">
              <ProgressBar progress={progress} />
            </Col>
            <Col flex="64px">
              <Button size="small" type="link">
                <Link to="/student/profiel">
                  <img className={styles.close} src={close} alt="close-x" />
                </Link>
              </Button>
            </Col>
          </Row>
        </div>
      </header>
      <div className={styles.signupContainer}>{!loading && <currentStep.component />}</div>
    </LayoutWizard>
  )
}

const ProfileWizard = (props: ProfileWizardProps) => {
  const { StepsContext, stepsState } = useSteps(props['*']);

  return (
    <StepsContext.Provider value={stepsState}>
      <ProfileWizardView {...props} />
    </StepsContext.Provider>
  );
};

export default ProfileWizard;
