import React from 'react';
import styles from './blocks.module.css';
import {
  CallToAction,
  LogoBar,
  DropletSectionList,
  RichText,
  Testimonials,
  Timeline,
  VideoEmbedSection,
  PhotoList,
  Spotlight,
} from '..';
import { pick } from '../../utils';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

export interface BlocksProps {
  blocks: any[],
  insetFirst?: boolean,
};

const Blocks = ({ blocks, insetFirst }: BlocksProps) => {
  return (
    <div className={cx('blocks', { insetFirst })}>
      {blocks.map((block, i) => {
        switch (block.__typename) {
          case 'STRAPI_ComponentContentPhotoList':
            return <PhotoList key={`${block.__typename}-${i}`} photos={block.photos} title={block.title} description={block.description} />
          case 'STRAPI_ComponentContentRichText':
            return <section style={{ paddingTop: 50, paddingBottom: 50 }} key={`${block.__typename}-${i}`} >
                <RichText text={block.text} />
              </section>
          case 'STRAPI_ComponentContentWhiteLogoBar':
            const logos = block.logos.map(({ logo, link }) => ({ link, imageUrl: logo.url }));
            return <LogoBar key={`${block.__typename}-${i}`} logos={logos} />
          case 'STRAPI_ComponentContentDropletSectionList':
            const sections = block.sections.map(section => ({
              ...pick(section, ['title', 'subTitle', 'paragraph', 'buttonLabel', 'buttonLink']),
              imageUrl: section?.image?.formats?.droplet?.url,
            }));
            return <DropletSectionList key={`${block.__typename}-${i}`} title={block.title} sections={sections} />;
          case 'STRAPI_ComponentContentSpotlight':
            return <Spotlight key={`${block.__typename}-${i}`} buttonLabel={block.buttonLabel} buttonLink={block.buttonLink} imageUrl={block.image.url} title={block.title} event={block.tb_event} activities={block.inhouse_days} />;
          case 'STRAPI_ComponentContentTimeline':
            return <Timeline
              key={`${block.__typename}-${i}`}
              conclusion={block.conclusion}
              steps={block.steps}
              title={block.title}
              version="event"
            />;
          case 'STRAPI_ComponentContentCallToAction':
            return <CallToAction key={`${block.__typename}-${i}`} {...pick(block, ['title', 'subTitle', 'buttonLabel', 'buttonLink', 'buttonType', 'color'])} />;
          case 'STRAPI_ComponentContentVideoEmbed':
            return <VideoEmbedSection key={`${block.__typename}-${i}`} {...pick(block, ['title', 'embedCode'])} thumbnailUrl={block.thumbnail.url} />;
          case 'STRAPI_ComponentContentTestimonialList':
            const testimonials = block.testimonials.map(testimonial => ({ ...testimonial, imageUrl: testimonial?.image?.url }));
            return <Testimonials key={i} testimonials={testimonials} title={block.title} />;
        }
      })}
    </div>
  )
};

export default Blocks;
