import React from 'react';
import { Button, Modal } from 'antd';
import { useActiveModal } from '../../hooks';

const EnrollModal = () => {
  const { modal, setActiveModal } = useActiveModal();

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-500)' }}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setActiveModal(null)}
      visible={modal === 'enroll'}
      width={500}
    >
      <h3><span className="turqoise">Ingeschreven!</span></h3>
			<p>
				Gefeliciteerd, je hebt je ingeschreven voor ons evenement!
				Ga snel naar je profiel om je voorkeuren op te geven.
			</p>

			<Button
				onClick={() => setActiveModal(null)}
				block
				style={{ marginTop: '32px' }}
			>
				Sluiten
			</Button>
    </Modal>
  );
};

export default EnrollModal;
