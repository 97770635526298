import React from 'react';
import { Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import close from '../../images/close.svg';
import { StudentTeam } from '../../utils';
import styles from './item.module.css';
import { Schedule } from '../profile/profile';

export interface StudentTeamItemProps {
  item: StudentTeam,
  onClick: () => void,
  destroyItem: () => void,
}

const StudentTeamItem = ({ item: studentTeam, onClick, destroyItem }: StudentTeamItemProps) => (
  <div className={styles.item}>
    <Row>
      <Col flex="calc(100% - 62px)">
        <h4>{studentTeam.name}</h4>
        <p>{studentTeam.position}</p>
        <Schedule source={studentTeam} />
      </Col>
      <Col flex="0 0 60px">
        <a onClick={onClick} className={styles.open}>
          <CaretRightOutlined onClick={onClick} style={{ fontSize: '28px', position: 'relative', top: '3px' }} />
        </a>
        <a className={styles.close} onClick={destroyItem}>
          <img src={close} alt="close-x" />
        </a>
      </Col>
    </Row>

  </div>
);

export default StudentTeamItem;
