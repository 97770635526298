import React, { useMemo, useState } from 'react';
import { navigate } from '@reach/router';
import { Alert, Button, Checkbox, Col, Form, Row } from 'antd';
import ReactMarkdown from 'react-markdown';
import { LayoutWizard, PickerHeader } from '../';
import { useAuth } from '../../hooks';
import styles from './picker.module.css';
import formStyles from '../formComponents/formComponents.module.css';
import { WarningOutlined } from '@ant-design/icons';
import { useBackendQuery } from '../../hooks/useBackendQuery';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { toIdDictionary } from '../../utils/toIdDictionary';
import { useBackendMutation } from '../../hooks/useBackendMutation';

const BACKEND_PROGRAM_ITEMS = gql`
  query {
    currentProgramItems {
      id
      strapiId
      location
      name
      numberOfStudents
      programItemTypeStrapiId
      startsAt
      company {
        name
      }
    }
  }
`

const UPDATE_PREFERRED_PROGRAM_ITEMS = gql`
  mutation setPreferredProgramItems($ids: [Int!]!) {
    setPreferredProgramItems(programItemIds: $ids) {
      ok
    }
  }
`

const PreEventsPicker = () => {
  const [setPreferredProgramItems] = useBackendMutation(UPDATE_PREFERRED_PROGRAM_ITEMS)
  const { data: currentProgramItemsData } = useBackendQuery(BACKEND_PROGRAM_ITEMS)
  const currentProgramItems = currentProgramItemsData?.currentProgramItems ?? [];
  const programItemTypeIds = new Set(currentProgramItems.map(programItem => `${programItem.programItemTypeStrapiId}`))
  const { data: programItemTypesData } = useQuery(gql`
    query {
      programItemTypes {
        color
        description
        id
        name
        header
        infoBlock
        programItems {
          id
          description
        }
      }
    }
  `);

  const programItemTypes = (programItemTypesData?.programItemTypes ?? []).flatMap((programItemType) => {
    if (!programItemTypeIds.has(programItemType.id)) return []
    const strapiProgramItemDictionary = toIdDictionary(programItemType.programItems)
    const programItems = currentProgramItems.flatMap(programItem => {
      if (programItem.programItemTypeStrapiId !== Number(programItemType.id)) return []
      return [{
        ...strapiProgramItemDictionary[`${programItem.strapiId}`],
        ...programItem,
      }]
    })
    return [{
      ...programItemType,
      startsAt: programItems[0].startsAt,
      programItems,
    }]
  }).sort((a, b) => a.startsAt.localeCompare(b.startsAt))

  const REQUIRED_PREFERRED_ITEMS = 3;

  const [activeTypeIndex, setActiveTypeIndex] = useState(0);
  const [preferred, setPreferred] = useState<{ [type: string]: string[] }>({});
  const [error, setError] = useState(false);
  const ids = useMemo(() => Object.values(preferred).flat(), [preferred]);

  const activeType = useMemo(() => programItemTypes[activeTypeIndex], [activeTypeIndex, programItemTypes]);

  const changePreferred = (e: string[], typeName: string) => {
    setPreferred({ ...preferred, [typeName]: e });
    setError(false);
  };

  const validate = (): boolean => {
    const activeIds = preferred[activeType.name];

    if (activeIds.length !== REQUIRED_PREFERRED_ITEMS) {
      setError(true);
      return false;
    }
    return true;
  };

  const next = () => {
    const valid = validate();
    if (valid) setActiveTypeIndex(activeTypeIndex + 1);
  };

  const submit = async () => {
    await setPreferredProgramItems({
      variables: {
        ids: ids.map(Number),
      },
    });
    navigate('/student/programma-compleet/');
  };

  if (!activeType && activeTypeIndex === 0) return null;

  return (
    <LayoutWizard>
      <PickerHeader />

      <div className="container-sm">
        {error && <Alert type="error" message="Het formulier bevat nog fouten" showIcon icon={<WarningOutlined />} />}

        {activeTypeIndex < programItemTypes.length ? (
          <>
            <h3>
              {activeType.header}{' '}
              <span className={styles.afterCircle} style={{ backgroundColor: activeType.color }}></span>
            </h3>
            <div>
              <span className="tag" style={{ background: activeType.color }}>
                {activeType.name}
              </span>
            </div>

            <Form className={formStyles.form} style={{ paddingBottom: 20, paddingTop: 20 }}>
              <Form.Item
                className={styles.formLabel}
                label={activeType.description}
                help={error && 'Kies 3 onderdelen'}
                validateStatus="error"
              >
                <Checkbox.Group className="checkbox-normal" onChange={e => changePreferred(e, activeType.name)}>
                  <Row gutter={16}>
                    {activeType.programItems.map((item, i: number) => {
                      return (
                        <Col key={i} lg={12}>
                          <Checkbox value={item.id}>{item.company.name}</Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Form>

            <div className="infoBlock">
              <ReactMarkdown source={activeType.infoBlock} />
            </div>

            <Row justify="center">
              <Col lg={8}>
                <Button block type="primary" onClick={next}>
                  Volgende
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <p>
              Je staat op het punt om jouw voorkeuren door te geven. Controleer het goed, want je kunt deze later niet
              meer zelf aanpassen.
            </p>
            <h4>Jouw top 3 voorkeuren</h4>

            {programItemTypes
              .filter(type => type.programItems.some(item => ids.includes(item.id)))
              .map((type, i) => (
                <div key={i} className={styles.preference}>
                  <p>
                    <span className="tag" style={{ background: type.color }}>
                      {type.name}
                    </span>
                  </p>
                  {type.programItems
                    .filter(item => ids.includes(item.id))
                    .map((item, j) => (
                      <p key={j}>{item.company.name}</p>
                    ))}
                </div>
              ))}

            <Row justify="center" style={{ marginTop: 40 }}>
              <Col lg={8}>
                <Button block onClick={() => setActiveTypeIndex(0)}>
                  Wijzigen
                </Button>
              </Col>
            </Row>

            <Row justify="center" style={{ marginTop: 24 }}>
              <Col lg={8}>
                <Button block type="primary" onClick={submit}>
                  Opslaan
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    </LayoutWizard>
  );
};

export default PreEventsPicker;
