import React from 'react';
import { Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import close from '../../images/close.svg';
import styles from './item.module.css';
import { Schedule } from '../profile/profile';

export interface JobItemProps {
  item: {
    companyName: string,
    jobTitle: string,
    hoursPerWeek: number,
    beginDate: Date,
    endDate: Date,
  },
  onClick: () => void,
  destroyItem: () => void,
};

const JobItem = ({ item: job, onClick, destroyItem }: JobItemProps) => {
  return (
    <div className={styles.item} >
      <Row>
        <Col flex="calc(100% - 52px)">
          <h4>{job.jobTitle}</h4>
          <p>{job.companyName}</p>
          <Schedule source={job} />
        </Col>
        <Col flex="0 0 25px">
          <CaretRightOutlined onClick={onClick} style={{ fontSize: "24px", position: "relative", top: "3px" }} />
          <img onClick={destroyItem} className={styles.close} src={close} alt="close-x" />
        </Col>
      </Row>
    </div>
  );
};

export default JobItem;
