import React from 'react';
import { UserCommittee } from '../../../utils';
import ItemsModal, { ItemsModalProps } from '../../formComponents/itemsModal';
import { Form, Input, Radio, Row, Col } from 'antd';

const CommitteesModal = (modalProps: ItemsModalProps<Partial<UserCommittee>>) => {
  const Modal = ItemsModal<Partial<UserCommittee>>();

  return (
    <Modal
      {...modalProps}
    >
      <h3><span className="turquoise">Commissie toevoegen</span></h3>
      <p className="infoBlock">
        Voeg alleen commissies toe waarbij je een eigen begroting en / of beleid gevoerd hebt.
      </p>

      <Form.Item
        label="Bij welke vereniging?"
        name="association"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Bij welke commissie?"
        name="name"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Welke functie had je hier?"
        name="position"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Het gaat om een"
        name="employmentType"
        rules={[{ required: true, message: "vul dit in" }]}
      >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            <Col lg={12}>
              <Radio value="PARTTIME">Parttime</Radio>
            </Col>
            <Col lg={12}>
              <Radio value="FULLTIME">Fulltime</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

    </Modal>
  )
}

export default CommitteesModal;
