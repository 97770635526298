import React, { FC } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { HomeOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { StrapiFooter, StrapiMenu, SocialPlatform } from '../../utils';
import Logo from '../../images/logo.svg';
import styles from './footer.module.css';

const socialLogos: { [key in SocialPlatform]: string } = {
  facebook: require('../../images/facebook.svg'),
  twitter: require('../../images/twitter.svg'),
  instagram: require('../../images/instagram.svg'),
  linkedin: require('../../images/linkedin.svg'),
};

interface QueryResult {
  strapi: {
    menu: StrapiMenu,
    footer: StrapiFooter,
  },
};

type Props = {
  dark: boolean
}

const Footer: FC<Props> = ({ dark }) => {
  const { strapi: { menu, footer }}: QueryResult = useStaticQuery(graphql`
    query {
      strapi {
        menu {
          items {
            __typename
            ... on STRAPI_ComponentMenuMenuItem {
              id
              label
              url
            }
          }
        }
        footer {
          address
          address2
          email
          phone
          terms
          socialPlatforms {
            platform
            url
          }
        }
      }
    }
  `);

  const style = {
    backgroundColor: dark ? '#002337' : '#012a40', // secondary-900 or 500
  };

  return <div style={style}>
    <section className={`container ${styles.footer}`}>
      <div className={styles.menu}>
        <Link to="/">Home</Link>
        {menu.items.map(item => {
          return item.__typename === "STRAPI_ComponentMenuMenuItem" ? <Link key={item.id} to={item.url}>{item.label}</Link> : null
        })}
      </div>
      <div className={styles.social}>
        {footer.socialPlatforms.map(socialPlatform => <Link key={socialPlatform.platform} to={socialPlatform.url}><img src={socialLogos[socialPlatform.platform]} /></Link>)}
      </div>
      <div className={styles.logoTerms}>
        <img src={Logo} alt="logo"/>
        <ReactMarkdown source={footer.terms} />
      </div>
      <div className={styles.contact}>
        <p><HomeOutlined />{footer.address}</p>
        <p>{footer.address2}</p>
        <p><PhoneOutlined rotate={90} /><a href={`tel:${footer.phone}`}>{footer.phone}</a></p>
        <p><MailOutlined /><a href={`mailto:${footer.email}`}>{footer.email}</a></p>
      </div>
    </section>
  </div>
};

export default Footer;
