import React from 'react';
import { useStep } from '../../../hooks';
import formStyles from '../../formComponents/formComponents.module.css';
import { Form, Input, Button } from 'antd';
import { pick } from '../../../utils';

interface FormData {
  linkedInProfile: string[]
}

const LinkedIn = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    (me) => pick(me, ['linkedInProfile']),
  );

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <h3><span className="turquoise">Connecten via LinkedIn</span></h3>

      <p>
        <b>(Optioneel)</b><br/>
        Tijdens het evenement is er de mogelijkheid om te connecten met bedrijven via de TBE app. <br/>
        Geef hier je LinkedIn-link op, zodat je gelijk nieuwe connecties aan kan gaan.
      </p>

      <Form.Item
        label="LinkedIn Profiel"
        name="linkedInProfile"
        rules={[{ required: false }]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Opslaan
        </Button>
      </Form.Item>

    </Form>
  )
}

export default LinkedIn;
