import React from 'react';
import { Form, Button } from 'antd';
import Timeline from '../../timeline/timeline';
import { useStaticQuery, graphql } from 'gatsby';

const Complete = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        timeline {
          ProfileComplete {
            title
            steps {
              title
              description
              id
              check
            }
          }
        }
      }
    }
  `);
  const { timeline } = strapi;

  return <div>
    <h3><span className="turquoise">{timeline?.ProfileComplete.title}</span></h3>

    <Timeline
      steps={timeline?.ProfileComplete?.steps}
      version="signup"
      forceVertical
    />

    <Form.Item>
      <Button
        block
        type="primary"
        href="/student/profiel/"
      >
        Terug naar mijn profiel
      </Button>
    </Form.Item>
  </div>;
}

export default Complete;
